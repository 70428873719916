
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/styles/global/reset.scss"

// mixins
import "./src/styles/mixins/screen-size-mixin.scss"

// default styling mainly for blog
import "./src/styles/global/defaults.scss"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// required by Bootstrap 5
import "@popperjs/core/dist/esm/popper.js"

// Bootstrap 5 https://getbootstrap.com/docs/5.0/customize/sass/
// SCSS variable in defaults.scss
import "bootstrap/dist/js/bootstrap.js";


